<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card-title>Search</v-card-title>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="7">
        <v-card class="ml-2">
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-card-subtitle>By ID</v-card-subtitle>
                <v-form v-model="searchByIdValid">
                  <v-container>
                    <v-row dense>
                      <v-col>
                        <v-text-field
                          v-model="searchId"
                          label="ID"
                          maxlength="50"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <v-btn
                          color="primary"
                          :disabled="!searchByIdValid"
                          :loading="resultsLoading"
                          min-width="225"
                          @click="handleGetMailingAddressByDefaultId"
                        >
                          Search By FileType ID
                        </v-btn>
                      </v-col>
                      <v-col>
                        <v-btn
                          color="primary"
                          :disabled="!searchByIdValid"
                          :loading="resultsLoading"
                          min-width="225"
                          @click="handleGetMailingAddressByIdMmsId"
                        >
                          search by MMS ID
                        </v-btn>
                      </v-col>
                      <v-col>
                        <v-btn
                          color="primary"
                          min-width="225"
                          :disabled="!searchByIdValid"
                          :loading="resultsLoading"
                          @click="handleGetMailingAddressByNpiId"
                        >
                          search by NPI
                        </v-btn>
                      </v-col>
                      <v-col>
                        <v-btn
                          color="primary"
                          :disabled="!searchByIdValid"
                          :loading="resultsLoading"
                          min-width="225"
                          @click="hanldeGetMailingAddressBySubFileId"
                        >
                          search by SubfileID
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-col>
              <v-col cols="6">
                <v-card-subtitle>By Name</v-card-subtitle>
                <v-form v-model="valid">
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="firstName"
                          :rules="[rules.required, rules.min1Char, rules.noSpecialChars]"
                          class="mb-3"
                          maxlength="30"
                          label="First Name"
                          hide-details="auto"
                        />
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="lastName"
                          :rules="[rules.required, rules.min2Char, rules.noSpecialChars]"
                          class="mb-3"
                          maxlength="30"
                          label="Last Name"
                          hide-details="auto"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="state"
                          :rules="[rules.min2Char, rules.max2Char, rules.isValidStateCode]"
                          class="mb-3"
                          maxlength="2"
                          label="State"
                          :disabled="!areStateOptionsLoaded"
                          hide-details="auto"
                          @keyup="uppercase"
                        />
                      </v-col>
                      <v-col>
                        <v-select
                          v-model="selectedFileType"
                          :items="fileTypeOptions"
                          label="FileType"
                          :disabled="!areFileTypeOptionsLoaded"
                          clearable
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="zipCode"
                          class="mb-3"
                          maxlength="30"
                          label="Zip / Postal Code"
                          hide-details="auto"
                          clearable
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-btn
                          color="primary"
                          :disabled="!valid"
                          @click="getBatchedMailingAddresses"
                        >
                          search by name
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col
        cols="4"
        class="mr-15"
      >
        <v-card class="mr-15">
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">
                  File Type
                </th>
                <th class="text-left">
                  # in Batch
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="batch in unexportedBatches"
                :key="batch.fileType"
              >
                <td>
                  {{ batch.fileType }}
                </td>
                <td>
                  {{ batch.waitingForExport }}
                </td>
                <v-tooltip top>
                  <template v-slot:activator="{ on: onTooltip }">
                    <v-btn
                      color="success"
                      dark
                      class="mt-1"
                      v-on="onTooltip"
                      @click="getMailingAddressesBatchedChanges(batch.fileType)"
                    >
                      <v-icon color="white">
                        mdi-file-download
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Export</span>
                </v-tooltip>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card class="ml-2">
          <v-row
            no-gutters
            justify="space-between"
          >
            <v-col
              sm="5"
              md="5"
            >
              <v-card-title class="ml-2">
                Search Results
              </v-card-title>
            </v-col>
            <v-col
              cols="3"
              class="pr-6"
              sm="3"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="addresses"
            :loading="resultsLoading"
            :search="search"
          >
            <template v-slot:top>
              <v-dialog
                v-model="dialog"
                max-width="800px"
              >
                <v-form
                  ref="form"
                  v-model="isValidEdit"
                >
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Edit Item</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.id"
                              label="ID"
                              disabled
                            />
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.fileTypeName"
                              label="FileType"
                              disabled
                            />
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.name"
                              label="Name"
                              disabled
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <div class="text-body-1">
                            Address
                          </div>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.address1"
                              :rules="[rules.requiredAddress1]"
                              label="Line 1"
                            />
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.address2"
                              :rules="[rules.requiredAddress2]"
                              label="Line 2"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.city"
                              :rules="[rules.required]"
                              label="city"
                            />
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.state"
                              :rules="[rules.required, rules.min2Char, rules.max2Char, rules.isValidStateCode]"
                              class="mb-3 uppercase"
                              maxlength="2"
                              label="State"
                              :disabled="!areStateOptionsLoaded"
                              hide-details="auto"
                              @keyup="uppercase"
                            />
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.zipCode"
                              :rules="[rules.required]"
                              label="Zip / Postal Code"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        :loading="postingBatchChange"
                        :disabled="postingBatchChange || !isValidEdit"
                        color="secondary"
                        @click="save"
                      >
                        Add to Batch
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-dialog>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                :id="item.id"
                :name="item.id"
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { nowwService, mailingAddressService } from '@/shared/services'
import { GetMailingAddressByIdIdType } from '@/shared/services/mailingAddress.service'

export default {
  name: 'MailingAddressManagement',
  data () {
    return {
      searchId: '',
      searchByIdValid: false,
      areFileTypeOptionsLoaded: false,
      areStateOptionsLoaded: false,
      postingBatchChange: false,
      fileTypeOptions: [],
      selectedFileType: '',
      valid: false,
      isValidEdit: false,
      rules: {
        required: (value) => !!value || 'Required.',
        requiredAddress1: (value) => {
          const currentFileType = this.editedItem.fileTypeName
          if (currentFileType !== 'AMA' && currentFileType !== 'AOA' && currentFileType !== 'ACCP') {
            return !!value || 'Required.'
          }
          return true
        },
        requiredAddress2: (value) => {
          const currentFileType = this.editedItem.fileTypeName
          if (currentFileType === 'AMA' || currentFileType === 'AOA' || currentFileType === 'ACCP') {
            return !!value || 'Required.'
          }
          return true
        },
        min1Char: (value) => (typeof value === 'string' && value.length >= 1) || 'Min 1 characters',
        min2Char: (value) => (typeof value === 'string' && (value.length >= 2 || value === "")) || 'Min 2 characters',
        max2Char: (value) => (typeof value === 'string' && (value.length <= 2 || value === "")) || 'Max 2 characters',
        isValidStateCode: (value) => value === "" || !!this.states.find((s) => value === s.state) || 'Must be a valid state code',
        noSpecialChars: (value) => {
          const pattern = /^[a-zA-Z0-9 _-]*$/
          return pattern.test(value) || 'No special characters allowed.'
        }
      },
      search: '',
      firstName: '',
      lastName: '',
      state: '',
      states: [],
      zipCode: '',
      orderResults: [],
      resultsLoading: false,
      unexportedBatches: [],
      dialog: false,
      headers: [
        {
          text: 'File Type',
          value: 'fileTypeName',
          align: 'start',
          sortable: true
        },
        { text: 'Name', value: 'name', align: 'start', sortable: true },
        { text: 'Addr1', value: 'address1', align: 'start', sortable: true },
        { text: 'Addr2', value: 'address2', align: 'start', sortable: true },
        { text: 'City', value: 'city', align: 'start', sortable: true },
        {
          text: 'State',
          value: 'state',
          align: 'start',
          sortable: false
        },
        { text: 'ZIP', value: 'zipCode', align: 'start', sortable: false },
        { text: 'Actions', value: 'actions', align: 'start', sortable: false }
      ],
      editedItem: {
        id: '',
        name: '',
        firstName: '',
        lastName: '',
        fileTypeName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: ''
      }
    }
  },
  computed: {
    ...mapGetters(['viewAsCustomer', 'simulatedCustomerCrmId']),
    ...mapGetters('auth', ['isMMSRole', 'getTenantId', 'getTenantCrmId']),
    showViewAsCustomer () {
      return this.$store.getters['auth/isMMSRole']()
    },
    addresses () {
      return this.orderResults.map(x => ({ ...x, name: `${x.firstName} ${x.lastName}` }))
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  mounted () {
    this.loadFileTypes(
      this.$store.getters['auth/getTenantCrmId']()
    )
    this.getStates()
    this.getMailingAddrUnexportedBatches()
  },
  methods: {
    uppercase () {
      this.state = this.state.toUpperCase()
      this.editedItem.state = this.editedItem.state.toUpperCase()
    },
    async getBatchedMailingAddresses () {
      this.resultsLoading = true
      await mailingAddressService
        .getBatchedMailingAddresses({
          firstName: this.firstName,
          lastName: this.lastName,
          state: this.state,
          zipCode: this.zipCode,
          fileType: this.selectedFileType
        })
        .then((res) => {
          if (res.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: res.errorResponse,
              color: 'error'
            })
          } else {
            this.orderResults = res
          }
          this.resultsLoading = false
        })
    },
    async getMailingAddressesBatchedChanges (fileType) {
      const res = await mailingAddressService.getMailingAddressesBatchedChanges(fileType).then((res) => {
        if (res.errorResponse) {
          this.$store.commit('setGlobalSnackbar', {
            message: res.errorResponse,
            color: 'error'
          })
        }

        const csvOutput = res
        const replacer = (key, value) => value === null ? '' : value
        const header = Object.keys(csvOutput[0] || {}).filter(x => x !== 'export')
        const csv = [
          header.join(','),
          ...csvOutput.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
        ].join('\r\n')
        const csvBlob = new Blob([csv], { type: 'application/text' })
        const blobUrl = URL.createObjectURL(csvBlob)
        const anchorElement = document.createElement('a')

        anchorElement.href = blobUrl
        anchorElement.download = `${fileType}.csv`
        anchorElement.click()

        setTimeout(() => {
          URL.revokeObjectURL(blobUrl)
        }, 500)
      }).finally(() => {
        this.getMailingAddrUnexportedBatches()
      })
    },
    async handleGetMailingAddressByDefaultId () {
      this.getMailingAddressByIdAndIdType(this.searchId, GetMailingAddressByIdIdType.Default)
    },
    async handleGetMailingAddressByIdMmsId () {
      this.getMailingAddressByIdAndIdType(this.searchId, GetMailingAddressByIdIdType.MmsId)
    },
    async handleGetMailingAddressByNpiId () {
      this.getMailingAddressByIdAndIdType(this.searchId, GetMailingAddressByIdIdType.Npi)
    },
    async hanldeGetMailingAddressBySubFileId () {
      this.getMailingAddressByIdAndIdType(this.searchId, GetMailingAddressByIdIdType.SubFile)
    },
    async getMailingAddressByIdAndIdType (id, idType) {
      this.resultsLoading = true

      const res = await mailingAddressService.getMailingAddressById(id, idType)

      if (res.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: res.errorResponse,
          color: 'error'
        })
      } else {
        this.orderResults = res
      }

      this.resultsLoading = false
    },
    async getMailingAddrUnexportedBatches () {
      const res = await mailingAddressService.getMailingAddrUnexportedBatches().then((res) => {
        if (res.errorResponse) {
          this.$store.commit('setGlobalSnackbar', {
            message: res.errorResponse,
            color: 'error'
          })
          this.unexportedBatches = []
        } else {
          this.unexportedBatches = res
        }
      })
    },
    async getStates () {
      await mailingAddressService.getStates().then((res) => {
        if (res.errorResponse) {
          this.$store.commit('setGlobalSnackbar', {
            message: res.errorResponse,
            color: 'error'
          })
          this.state = []
        } else {
          this.states = res
        }
        this.areStateOptionsLoaded = true
      })
    },
    async postBatchedChanges () {
      this.postingBatchChange = true
      const {
        firstName,
        lastName,
        address1,
        address2,
        city,
        state,
        zipCode,
        fileTypeName,
        id
      } = this.editedItem
      const res = await mailingAddressService.postBatchedChanges({
        firstName,
        lastName,
        adr1: address1,
        adr2: address2,
        city,
        state,
        zip: zipCode,
        fileType: fileTypeName,
        fileId: id
      })
      if (res.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: res.errorResponse,
          color: 'error'
        })
      }
      this.getMailingAddrUnexportedBatches()
      this.postingBatchChange = false
    },
    async loadFileTypes (tenantId) {
      await nowwService
        .getFileTypes(tenantId)
        .then(res => {
          if (res.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: res.errorResponse,
              color: 'error'
            })
            this.fileTypeOptions = []
          } else {
            this.fileTypeOptions = res.map(x => x.fileTypeName)
            this.areFileTypeOptionsLoaded = true
          }
        })
    },
    editItem (item) {
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    async save () {
      await this.postBatchedChanges()
      this.close()
    }
  }
}
</script>

<style>
  .uppercase input {
    text-transform: uppercase;
  }
</style>
